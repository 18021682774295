import React, { useState, useEffect } from 'react';
import qs from 'query-string';
import { Typography, Box, Grid } from '@mui/material';
import { stdDate, useApi, makeUrl, NONE_FILTER
} from '@clients/shared/src/utils';
import { useWorkplace } from '@clients/shared/src/place/services';
import { eventServices } from '@clients/shared/src/event/event-services';
import { filterPlaceByText } from '@clients/shared/src/place/utils';
import useToursState from '@clients/shared/src/tour/toursState';
import { Load } from '../_utils';
import { SearchBar, useSearch, useGetModels } from '../searchbar';
import { Page, H6, Frame} from '../main';
import EventsPeriodsList from './EventsPeriodsList';


/** A component to display events (deliveries, returns) history */
export default function History ({location}) {
  const [ inners, setInners ] = useState([]);
  const [ externs, setExterns ] = useState([]);
  const workplace = useWorkplace();
  const apiInnerEvents = useApi();
  const apiExtEvents = useApi();
  const search =
    qs.parse(location.search, {parseBooleans: true, parseNumbers: true});
  const {after, before, include, showReturns, showDeliveries} = search;
  const withFollowups = (include==="next_event")
  useEffect(() => {
    apiInnerEvents.get(makeUrl('/events', {...search, light_data:true}))
      .then(events => setInners(events.filter(
        e => e.destination && e.destination_id!==workplace.id)), ()=>{});
    apiExtEvents.get(
      makeUrl('/events', {...search, to_receive: true, light_data: true})
    ).then(setExterns, ()=>{});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [after, before, withFollowups, workplace.id]);

  const models = useGetModels(search)
  const allEvents = [...externs, ...inners];
  const caretakers = [...new Set(allEvents.map(e => e.caretaker))]
    .map(c => ({label: c || 'Sans locataire', value: c || NONE_FILTER}));
  const { tours } = useToursState(workplace.id);
  const filters = [
    {type: 'text', 
      name: 'text', 
      filterFn: (text, item) => filterPlaceByText({name: item.destination},text)
    },
    {template: 'workplace'},
    {template: 'after', initialValue: stdDate(-7)},
    {template: 'before'},
    {template: 'models', items: models, key: 'bac_model_id'},
    {template: 'caretaker', items: caretakers},
    {template: 'tours', items: tours, key: 'destination_id'},
    {template: 'clean'},
    {template: 'notReturned', hideOnFollowup: true},
    {name: 'showDeliveries',
      label: 'Livraisons',
      type: 'switch',
      hideOnFollowup: true,
      filterFn: (isOn, item) =>
        !isOn || showReturns || item.destination_id !== workplace.id
    },
    {name: 'showReturns',
      label: 'Réceptions',
      type: 'switch',
      hideOnFollowup: true,
      filterFn: (isOn, item) =>
        !isOn || showDeliveries || item.destination_id === workplace.id
    }
  ].filter(f => !withFollowups || !f.hideOnFollowup);
  const [searchBarProps, events] = useSearch(filters, allEvents);
  const periods = eventServices.groupByPeriod(events);
  
  return (
    <Page title={withFollowups ? 'Suivi livraisons' : 'Historique'}>
      <SearchBar {...searchBarProps}/>
      <Load isLoading={apiInnerEvents.isLoading || apiExtEvents.isLoading}>
        {periods.length > 0
          ? <>
              <Grid container
                justifyContent='center'
                sx={(theme) => ({
                  m:0,
                  width: '100%', 
                  boxShadow: theme.components.shadows.defaultProps.inner
                })}
              >
                <Grid item container sx={{p: 3}}>
                  <Frame>
                    {events.filter(e => e.destination_id!==workplace.id).length}
                  </Frame>
                  <H6>Envois</H6>
                </Grid>
                <Grid item container sx={{p: 3}}>
                  <Frame secondary>
                    {events.filter(e => e.destination_id===workplace.id).length}
                  </Frame>
                  <H6>Réception</H6>
                </Grid>
              </Grid>
              <EventsPeriodsList {...{periods,placeId:workplace.id,...search}}/>
            </>
          : (
            <Box display='flex' my={8} p={2} justifyContent='center'>
              <Typography>
                Aucun résultat, essayez de retirer des filtres 
                ou modifier les dates sélectionnées.
              </Typography>
            </Box>
          )}
      </Load>
    </Page>
  );
}
