import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { useManagePlaceRef, usePlaceOrState
} from '@clients/shared/src/place/services';
import { FormPage, SubmitButton, Card } from '../main';

/** The form to add a ref of a place. */
export default function AddRef ({match}) {
  const { place_id: target_id } = match.params;
  const [target,] =  usePlaceOrState(target_id);
  const [ submitSucceeded, setSucceeded ] = useState(false);
  const { placeRef, changeRef } = useManagePlaceRef(target);
  const initialValues = {id: '', ref: '', ...(placeRef || {}) };
  const onSubmit = values => changeRef(values.ref).then(()=>setSucceeded(true));
  return (
    <FormPage title={`Votre réf interne pour ${target.name || target_id}`}>
      <Card title={`${target?.ref ? 'Modification' : 'Ajout'} d'une réf`}>
        <Formik {...{initialValues, onSubmit}} enableReinitialize>
          <Form>
            <Field
              name='ref'
              label='Référence'
              component={TextField}
              margin='dense'
              variant="standard"
              fullWidth
            />
            <SubmitButton {...{ submitSucceeded}}/>
          </Form>
        </Formik>
      </Card>
    </FormPage>)
};
