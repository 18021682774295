import { useState, useEffect } from "react";
import { useApi, NONE_FILTER, makeUrl } from "@clients/shared/src/utils";

//** Get caretakers hook. */
export default function useGetCaretakers ({after, before, workplace_id}) {
    const [caretakers, setCaretakers] = useState([]);
    const api = useApi({disableConcurrentRequest: true});
    useEffect(() => {
        api.get(makeUrl('/caretakers', {after, before, workplace_id}))
            .then(c => [...c, {id: NONE_FILTER, name: 'Sans locataire'}])
            .then(setCaretakers);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [after, before, workplace_id]);
    return caretakers
}
