import React, { useEffect, useState} from 'react';
import { addMonths } from "date-fns";
import { Formik, Form, Field } from 'formik';
import { Select } from 'formik-mui';
import { MenuItem, Grid, Box } from '@mui/material';
import { subDays, subWeeks, subMonths } from 'date-fns/esm';
import { useApi, makeUrl } from '@clients/shared/src/utils';
import { useWorkplace } from '@clients/shared/src/place/services';
import { Page, DatePicker } from '../main'
import { Load } from '../_utils';
import { SearchBar, useSearch } from '../searchbar';
import { ActivityLineChart } from './ActivityLineChart';
import { ActivityBarChart } from './ActivityBarChart';


/** Page displaying a place activity. */
export default function Activity () {
  const apiDeliveries = useApi({disableConcurrentRequest: true});
  const apiReturns = useApi({disableConcurrentRequest: true});
  const [ returns, setReturns ] = useState([]);
  const [ deliveries, setDeliveries ] = useState([]);
  const workplace = useWorkplace();
  const filters = [{template: 'workplace'}];
  const [ searchBarProps, ] = useSearch(filters, []);
  const queryParams = {
    after: addMonths(new Date(), -12),
    workplace_id: workplace.id,
    light_data: true
  };

  useEffect(() => {
    apiReturns.get(makeUrl('/events', {...queryParams, to_receive: true}))
      .then(setReturns, ()=>{});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workplace.id, apiReturns.get]);

  useEffect(() => {
    apiDeliveries.get(makeUrl('/events', queryParams))
      .then(events => setDeliveries(
        events.filter(({destination_id: id}) => (id && (id !== workplace.id)))))
      .catch(()=>{});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workplace.id, apiDeliveries.get]);

  return (
    <Page title='Activité'>
      <SearchBar {...searchBarProps}/>
      <Grid item>
        <Box my={4} mx='auto' p={2}>
          <Formik initialValues={{interval:'days', start:setStartDate('days')}}>
            {({values, setValues}) => (
            <Form>
              <Grid container justifyContent='space-between'>
                <Field
                  name='interval'
                  component={Select}
                  variant="standard"
                  style={{margin: '0 10px'}}
                  onChange={event => {
                    const interval = event.target.value;
                    setValues({interval, start: setStartDate(interval),})}}
                >
                  {intervals.map(({name, value}) => 
                  <MenuItem key={value} value={value}>{name}</MenuItem>)}
                </Field>
                <DatePicker name='start'/>
              </Grid>
              <Load isLoading={apiDeliveries.isLoading || apiReturns.isLoading}>
                <Box my={5} component={ActivityLineChart}
                  interval={values.interval}
                  start={values.start}
                  returns={returns}
                  deliveries={deliveries}
                />
                <Box my={5} component={ActivityBarChart}
                  start={values.start}
                  end={new Date()}
                  deliveries={deliveries}
                />
              </Load>
            </Form>)}
          </Formik>
        </Box>
      </Grid>
    </Page>)
};

const intervals = [
  { name: 'Jours', value: 'days'},
  { name: 'Semaines', value: 'weeks'}, 
  { name: 'Mois', value: 'months'}
];

const setStartDate = interval => {
  if (interval === 'weeks') {
    return (subWeeks(new Date(), 12).toISOString());
  } else if (interval === 'months') {
    return (subMonths(new Date(), 12).toISOString());
  };
  return (subDays(new Date(), 30).toISOString());
};

