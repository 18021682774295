import React from 'react';
import QRCode from 'qrcode.react';
import { Typography, Grid } from '@mui/material';
import { CloudOff } from '@mui/icons-material';
import { hostUrl } from './CreateQR'
import './qr.css';
import logoWithName from './PANDOBAC_Logotype_couleur_web_T3.png';
import logoIcon from './PANDOBAC_Icone_RVB.png';


export const range = (size, startAt = 0, pace = 1) => {
  return [...Array(size).keys()].map(i => pace*i + startAt);
}


export const Sticker = ({value, page, name, unsync}) => {
  const code = value.toString();
  //TODO see how to handle GRAI value with more style
  const displayCode = (code.length<18) ? code : "..."+code.slice(18)
  const logo = (page.path === '/places/') ? logoIcon : logoWithName;
  return (
    <Grid 
      item 
      container 
      justifyContent='space-between'
      alignItems='center'
      spacing={5}
    >
      <Grid item
        component={QRCode}
        xs='auto'
        size={80} 
        value={hostUrl + page.path + code}
        className='noPrint'
      />
      <Grid item xs container alignItems='center' justifyContent='center'
        direction="column" sx={{mx: 2}}
      >
        <Grid item xs>
          <Typography align='center'>
            {name || page.name + ' ' + displayCode}
          </Typography>
        </Grid>
        <Grid item className='noPrint'>
          {unsync
            ? <CloudOff />
            : <img style={{height: 10}} src={logo} alt='Logo Pandobac'/>
          }
        </Grid>
      </Grid>
    </Grid>
  );
}


const StickerRow = (props) => {
  //const style = {margin: '12mm 6mm', marginRight: '2.5mm'}
  return (
    <div 
      className='row' 
      style={{gridTemplateColumns: 'repeat(' + props.col + ', 1fr)'}}
    >
      { props.stickers.map((sticker, index) => 
          <Sticker 
            key={index} 
            value={sticker.code} 
            name={sticker.name} 
            page={props.page}
          />
        )
      }
    </div>
    );
}

export const StickerGrid = (props) => {
  const { stickers, col, page } = props;
  return (
    <div className='QRgrid'>
      { range(~~(Math.ceil(stickers.length/col))).map(index => (
          <StickerRow 
            key={index}
            col={col} 
            stickers={stickers.slice(index*col, (index+1)*col)}
            page={page}
          />
      ))}
    </div>
  )
}
